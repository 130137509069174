.w-20p {
  width: 20% !important;
}
.w-20p {
  width: 20% !important;
}
.w-60p {
  width: 60% !important;
}

@media screen and (max-width: 480px) {
  .w-20p {
    width: 90% !important;
  }
  .w-30 {
    width: 90% !important;
  }
  .w-80 {
    width: 90% !important;
  }
  .w-40 {
    width: 90% !important;
  }
  .w-60p {
    width: 90% !important;
  }
  .w-50 {
    width: 90% !important;
  }
}
