.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-80 {
  width: 80%;
}
.w-40 {
  width: 40%;
}
.w-60p {
  width: 60%;
}
.bg-body {
  background-color: #f5f5f5;
}
.text-22 {
  font-size: 22px;
}
.text-30 {
  font-size: 30px;
}
.text-20 {
  font-size: 20px;
}
.text-15 {
  font-size: 15px;
}
.text-14 {
  font-size: 14px;
}
.text-18 {
  font-size: 18px;
}
.text-theme-gray {
  color: #666666;
}
.mt-100 {
  margin-top: 50px;
}
.feature-hover:hover {
  background-color: #6666660d;
}

.bg-wholeslae {
  background-color: #f9f5f0e9;
}

.postion-wholesale {
  top: 21%;
  border-radius: 8px;
}
.bg-testimonial {
  background-color: #f9f5f0;
}
.py9060 {
  padding-top: 90px;
  padding-bottom: 60px;
}

.px60 {
  padding: 0px 60px;
}
.px120 {
  padding: 0px 120px;
}
.profile-border {
  border-radius: 10px;
}
.dp-text {
  color: #1a1a1a;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.bg-theme-color {
  background-color: #ecb30a;
  width: 28px;
  height: 28px;
  border-radius: 10px;
}
.pt2px {
  padding-top: 2px;
}
.text-theme {
  color: #ecb30a !important;
}
.bg-themeYellow {
  background-color: #ecb30a;
}
.w-100px {
  width: 90px;
  height: 90px;
}
/* order */
.order-bg {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.text-invoice {
  font-size: 30px;
  font-weight: 600;
}
.text-delivary {
  color: #ecb30a;
  font-size: 25px;
  font-weight: 600;
}
.w-90px {
  width: 80px;
  height: 80px;
}
.btn-theme-hover:hover {
  background-color: #ecb30a;
  border-radius: 11px;
  color: #ffffff;
}
.btn-theme-order {
  width: 210px;
  height: 50px;
}
.status-com {
  color: #05b036;
}
.text-75 {
  font-size: 75px;
  font-weight: 600;
}
.sideItem:hover {
  cursor: pointer;
  color: #ecb30a;
  font-weight: 600;
}
.border-midddle {
  border-right: #66666669 1px solid !important;
}
.card-img2 {
  display: block;
  width: 100%;
  height: auto;
}

.card-main22 {
  width: 23% !important;
}
.card-img2:hover {
  transform: scale(1.1);
  transition: all 0.8s ease-in-out;
}
.displayHiddenSm {
  display: block;
}
.displayHiddenSmV {
  display: none;
}
@media screen and (min-width: 481px) and (max-width: 1280px) {
}

@media screen and (max-width: 480px) {
  .w-20 {
    width: 90% !important;
  }
  .w-30 {
    width: 90% !important;
  }
  .w-80 {
    width: 90% !important;
  }
  .w-40 {
    width: 90% !important;
  }
  .w-60p {
    width: 90% !important;
  }
  .w-50 {
    width: 90% !important;
  }
  .px60 {
    padding-left: 5%;
    padding-right: 5%;
  }
  .text-invoice {
    font-size: 18px !important;
    font-weight: 600;
  }
  .border-midddle {
    border-right: #66666669 0px solid !important;
  }
  .card-main22 {
    width: 100% !important;
  }
  .img-card {
    width: 90% !important;
  }
  .text-16 {
    font-size: 14px !important;
  }
  .displayHiddenSm {
    display: none !important;
  }
  .displayHiddenSmV {
    display: block !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 1280px) {
  .card-main {
    width: 30% !important;
  }
}
