.bg-footer {
  background-color: #f5f5f5;
}

.bg-outline-white {
  border: 1px solid white;
}
.w-30 {
  width: 33%;
}
.text-16 {
  font-size: 14px;
  color: #666666;
}
.border-start-end {
  border-left: #66666663 1px solid;
  border-right: #66666663 1px solid;
}
.containerpro {
  padding-left: 10%;
  padding-right: 10%;
}
.bg-black-pro {
  background-color: #363636;
  height: 50px;
}
.text-white-50 {
  color: #ffffff7a;
}

@media screen and (min-width: 581px) and (max-width: 1280px) {
  .border-start-end {
    border-left: #66666663 1px solid;
    border-right: #66666663 1px solid;
  }
  .px90 {
    padding-left: 20px;
  }
  .text-14 {
    font-size: 10px;
  }
}
@media screen and (max-width: 480px) {
  .border-start-end {
    border: 0 !important;
  }
}
